import * as $ from 'jquery';
import 'select2';
import EditorJS from '@editorjs/editorjs';
import Header from '@editorjs/header';
import List from '@editorjs/list';
import ImageTool from '@editorjs/image';
import Paragraph from '@editorjs/paragraph';
import CodeTool from '@editorjs/code';
import InlineCode from '@editorjs/inline-code';
import Quote from '@editorjs/quote';

$(function() {
  const postId = $('.post-id').data('post-id')
  const $editorHolder = $('#content__editorjs');
  const authToken = $('input[name="authenticity_token"]').val();

  if ($editorHolder[0] !== undefined){
    const data = $('.post-content').data().content;
    const $toggleFontsizeEl = $('.toggle__font-size a');
    const $toggleFontsizeWrapperEl = $toggleFontsizeEl.parent();

    const editor = new EditorJS({
      /**
       * Id of Element that should contain Editor instance
       */
      holder: 'content__editorjs',

      data: data,

      /**
       * Available Tools list.
       * Pass Tool's class or Settings object for each Tool you want to use
       */
      tools: {
        header: Header,
        list: List,
        image: {
          class: ImageTool,
          config: {
            additionalRequestData: {
              postId: postId,
              authenticity_token: authToken
            },
            endpoints: {
              byFile: '/posts/image/uploadFile', // Your backend file uploader endpoint
              byUrl: '/posts/image/fetchUrl', // Your endpoint that provides uploading by Url
            }
          },
        },
        paragraph: {
          class: Paragraph,
          inlineToolbar: true,
        },
        code: CodeTool,
        inlineCode: {
          class: InlineCode,
          shortcut: 'CMD+SHIFT+M',
        },
        quote: {
          class: Quote,
          inlineToolbar: true,
          shortcut: 'CMD+SHIFT+O',
          config: {
            quotePlaceholder: 'Enter a quote',
            captionPlaceholder: 'Quote\'s author',
          },
        },
      },
    });

    (async function initEditor() {
      try {
        await editor.isReady;

        const $editorText = $('.ce-paragraph');
        const $contentH1 = $('#content__editorjs h1');
        const $contentH2 = $('#content__editorjs h2');

        $toggleFontsizeEl.on('click', function (e) {
          if ($toggleFontsizeWrapperEl.hasClass('active')) {
            $editorText.css('font-size', '1.2rem');
            $contentH1.css('font-size', 'calc(1em * 1.250 * 1.250 * 1.250 * 1.250)');
            $contentH2.css('font-size', '');

            $toggleFontsizeWrapperEl.removeClass('active');

            $toggleFontsizeEl.html('Toggle Font-size (increase)');
          } else {
            $editorText.css('font-size', '1.8rem');
            $contentH1.css('font-size', 'calc(1.2em * 1.250 * 1.250 * 1.250 * 1.250)');
            $contentH2.css('font-size', 'calc(1em * 1.250 * 1.250 * 1.250 * 1.250)');
            $toggleFontsizeWrapperEl.addClass('active');

            $toggleFontsizeEl.html('Toggle Font-size (reduce)');
          }
        });

      } catch (reason) {
        console.error(`Editor.js initialization failed because of ${reason}`)
      }
    })()
      .catch(e => {
        console.error('Error during Editor.js initialization: ' + e.message);
      });

    $('.select2').select2({
      tags: true,
      tokenSeparators: [',']
    });

    // Select existing tags in the Select2 field
    const $selectorEl = $('select[name="post[tag_names]"]');
    const selected = [];
    if ($selectorEl.data().tags !== "") {
      $selectorEl.data().tags.forEach((tag) => {
        selected.push(tag.name);
      });
      $selectorEl.val(selected);
      $selectorEl.trigger('change');
    }

    $('#new_post input[type="submit"]').on('click', (e) => {
      e.preventDefault();
      let authToken = $('input[name="authenticity_token"]').val();

      const formEl = document.getElementById('new_post');
      let formData = new FormData(formEl);

      editor.save().then((outputData) => {
        let outputDataBase64 = btoa(JSON.stringify(outputData))
        formData.append('editor', outputDataBase64)

        const formDataEntries = [...formData.entries()];
        let tags = [];

        formDataEntries.forEach((elem) => {
          if (elem[0] === "post[tag_names]") {
            tags.push(elem[1]);
          }
        })

        const formDataAsString = formDataEntries
          .map(x => {
            let result;

            if (x[0] !== "post[tag_names]") {
              result = `${encodeURIComponent(x[0])}=${encodeURIComponent(x[1])}`
            } else {
              result = `${encodeURIComponent(x[0])}=${encodeURIComponent(tags)}`
            }

            return result;
          })
          .join('&');

        $.ajax({
          url: '/api/internal/posts',
          method: "POST",
          data: formDataAsString
        })
          .done(function(data, textStatus, jqXHR) {
            let {success, redirectTo} = data;
            if (success) {
              window.location.href = redirectTo;
            }
          })
          .fail(function(jqXHR, textStatus, errorThrown) {
            console.error(textStatus);
          });
      }).catch((error) => {
        console.log('Saving failed: ', error)
      });
    })

    $('.edit_post input[type="submit"]').on('click', (e) => {
      e.preventDefault();
      let authToken = $('input[name="authenticity_token"]').val();
      const postId = $('.post-id').data().postId;

      const formEl = document.getElementsByClassName('edit_post');
      let formData = new FormData(formEl[0]);

      editor.save().then((outputData) => {

        // FIXME:
        // Error: Update failed:  DOMException: Failed to execute 'btoa' on 'Window': The string to be encoded contains characters outside of the Latin1 range
        // Refer to: https://stackoverflow.com/a/63387912/449342
        //
        // function base64(data) {
        //   const bytes = new TextEncoder().encode(data);
        //   const binString = String.fromCodePoint(...bytes);
        //   return btoa(binString);
        // }
        // function decode64(base64) {
        //   const binString = atob(base64);
        //   const bytes = Uint8Array.from(binString, (m) => m.codePointAt(0));
        //   return new TextDecoder().decode(bytes);
        // }

        let outputDataBase64 = btoa(JSON.stringify(outputData))
        formData.append('editor', outputDataBase64)

        const formDataEntries = [...formData.entries()];
        let tags = [];

        formDataEntries.forEach((elem) => {
          if (elem[0] === "post[tag_names]") {
            tags.push(elem[1]);
          }
        })

        const formDataAsString = formDataEntries
          .map(x => {
            let result;

            if (x[0] !== "post[tag_names]") {
              result = `${encodeURIComponent(x[0])}=${encodeURIComponent(x[1])}`
            } else {
              result = `${encodeURIComponent(x[0])}=${encodeURIComponent(tags)}`
            }

            return result;
          })
          .join('&');

        $.ajax({
          url: `/api/internal/posts/${postId}`,
          method: "PATCH",
          data: formDataAsString
        })
          .done(function(data, textStatus, jqXHR) {
            let {success, redirectTo} = data;
            if (success) {
              window.location.href = redirectTo;
            }
          })
          .fail(function(jqXHR, textStatus, errorThrown) {
            console.error(textStatus);
          });
      }).catch((error) => {
        console.log('Update failed: ', error)
      });
    })

  }
});
